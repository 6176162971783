import React from "react";
import { graphql } from 'gatsby';

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageNotFound from '../template/pages/PageNotFound';

const Page = (props) => {
    return <>
      <Layout {...props}>
          <SEO />
          <PageNotFound {...props} />
      </Layout>
    </>
}

export default Page;
